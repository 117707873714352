import React from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';

import AddProductModal from './AddProductModal';
import ProductTable from './ProductTable';
import { apiUrl } from '../../config/Constants';

import './Admin.css';

class Admin extends React.Component {

  constructor() {
    super();
    this.state = {
      ingredients: { },
      feedCategories: [ ],
      feedProperties: [ ],
      healthIssues: [ ],
      showAddProduct: false,
      productsAddedCount: 0
    }
  }

  componentDidMount() {
    axios.get(apiUrl + "/ingredients")
      .then(res => this.setState({ ingredients: res.data }));
    axios.get(apiUrl + "/feedCategories")
      .then(res => this.setState({ feedCategories: res.data.feedCategories }));
    axios.get(apiUrl + "/feedProperties")
      .then(res => this.setState({ feedProperties: res.data.feedProperties }));
    axios.get(apiUrl + "/healthIssues")
      .then(res => this.setState({ healthIssues: res.data.healthIssues }));
  }

  showAddProductModal() {
    this.setState({ showAddProduct: true });
  }

  hideAddProductModal = (successful) => {
    this.setState({ 
      showAddProduct: false,
      productsAddedCount: this.state.productsAddedCount + (successful ? 1 : 0)
    });
  }

  render() {
    return (
        <div className="Admin">
          <h4>Feed-What-Fits</h4>
          <h3>Admin-Bereich</h3>
          <p></p>
          <Button variant="outline-primary" onClick={() => this.showAddProductModal()}>➕ Produkt hinzufügen</Button>
          <AddProductModal 
            show={this.state.showAddProduct} 
            onComplete={this.hideAddProductModal}
            ingredients={this.state.ingredients}
            feedCategories={this.state.feedCategories}
            feedProperties={this.state.feedProperties}
            healthIssues={this.state.healthIssues} 
          />
          <p></p>
          <ProductTable
            productsAddedCount={this.state.productsAddedCount}
          />
        </div>
    );
  }
}

export default Admin;
