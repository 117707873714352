import './Main.css';

function Main() {
  return (
    <div className="Main">
      <h2>Feed-What-Fits</h2>
      <h1>🐶</h1>
      <p>Diese Seite befindet sich noch im Aufbau ...</p>
    </div>
  );
}

export default Main;
