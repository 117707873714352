import React from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';

import { apiUrl } from '../../config/Constants';

import './AddProductModal.css';

class AddProductModal extends React.Component {

  constructor() {
    super();
    this.state = {
      ingredientGroupsShow: [],
      productName: "",
      feedCategoriesChecked: [],
      feedPropertiesChecked: [],
      ingredientsChecked: [],
      healthIssuesChecked: [],
      saveEnabled: true
    }
  }

  toggleIngredientGroupShow(igIdx) {
    const newIgs = this.state.ingredientGroupsShow;
    newIgs[igIdx] = newIgs[igIdx] ? false : true;
    this.setState({
      ingredientGroupsShow: newIgs
    });
  }

  changeProductName(newProductName) {
    this.setState({
      productName: newProductName
    });
  }

  cleanUpAndComplete = (saved) => {
    this.setState({
      ingredientGroupsShow: [],
      productName: "",
      feedCategoriesChecked: [],
      feedPropertiesChecked: [],
      ingredientsChecked: [],
      healthIssuesChecked: [],
      saveEnabled: true
    });
    this.props.onComplete(saved);
  }

  save = () => {
    this.setState({saveEnabled: false}, () => {
      axios.post(apiUrl + "/products", {
          name: this.state.productName,
          feedCategories: this.state.feedCategoriesChecked,
          feedProperties: this.state.feedPropertiesChecked,
          ingredients: this.state.ingredientsChecked,
          healthIssues: this.state.healthIssuesChecked
        })
        .then(res => this.cleanUpAndComplete(true))
        .catch(err => this.setState({saveEnabled: true}));
    });
  }

  toDativeCase(word) {
    if (word.toLowerCase().endsWith("probleme")) {
      return (word + "n");
    }
    return word;
  }

  changeArrayAndReturn(arr, val, add) {
    if (add) {
      arr.push(val);
    }
    else {
      const index = arr.indexOf(val);
      arr.splice(index, 1);
    }
    return arr;
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={() => this.cleanUpAndComplete(false)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Produkt hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>

            <h5>Name:</h5>
            <Form.Control type="text" value={this.state.productName} onChange={(evt) => this.changeProductName(evt.target.value)} />

            <h5 className="AddProductModal-margin-top">Futterkategorie:</h5>
            {
              this.props.feedCategories.map(category => (
                <Form.Check className="AddProductModal-checkbox" type="checkbox" 
                  label={category} key={category} onChange={(evt) => this.setState({ 
                    feedCategoriesChecked: this.changeArrayAndReturn(this.state.feedCategoriesChecked, category, evt.target.checked)
                  })}
                />
              ))
            }

            <h5 className="AddProductModal-margin-top">Zutaten:</h5>
            {
              Object.keys(this.props.ingredients).map((ingredientGroup, igIdx) => (
                <div key={ingredientGroup}>
                  <div className="AddProductModal-ingredient-group" key={ingredientGroup}>
                    <span onClick={() => this.toggleIngredientGroupShow(igIdx)}>
                      {ingredientGroup} {this.state.ingredientGroupsShow[igIdx] ? "˅" : "˃"}
                    </span>
                  </div>
                  <div style={{display: this.state.ingredientGroupsShow[igIdx] ? "block" : "none"}}>
                    {
                      this.props.ingredients[ingredientGroup].map(ingredient => (
                        <Form.Check className="AddProductModal-checkbox" type="checkbox" 
                          label={ingredient} key={ingredient} onChange={(evt) => this.setState({ 
                            ingredientsChecked: this.changeArrayAndReturn(this.state.ingredientsChecked, ingredient, evt.target.checked)
                          })}
                        />
                      ))
                    }
                  </div>
                </div>
              ))
            }

            <h5 className="AddProductModal-margin-top">Weitere Eigenschaften:</h5>
            {
              this.props.feedProperties.map(prop => (
                <Form.Check className="AddProductModal-checkbox" type="checkbox" 
                  label={prop} key={prop} onChange={(evt) => this.setState({ 
                    feedPropertiesChecked: this.changeArrayAndReturn(this.state.feedPropertiesChecked, prop, evt.target.checked)
                  })}
                />
              ))
            }

            <h5 className="AddProductModal-margin-top">Auch verträglich bei:</h5>
            {
              this.props.healthIssues.map(issue => (
                <Form.Check className="AddProductModal-checkbox" type="checkbox" 
                  label={this.toDativeCase(issue)} key={issue} onChange={(evt) => this.setState({ 
                    healthIssuesChecked: this.changeArrayAndReturn(this.state.healthIssuesChecked, issue, evt.target.checked)
                  })}
                />
              ))
            }

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => this.cleanUpAndComplete(false)}>Abbrechen</Button>
          <Button variant="outline-primary" onClick={this.save} disabled={!this.state.saveEnabled}>Speichern</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default AddProductModal;
