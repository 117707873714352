import React from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';

import { apiUrl } from '../../config/Constants';

import './ProductTable.css';

class ProductTable extends React.Component {

  constructor() {
    super();
    this.state = {
      products: [],
      page: 1,
      totalPages: 1,
      productsAddedCount: 0
    };
  }

  componentDidMount() {
    this.retrieveProducts(1);
  }

  componentDidUpdate() {
    if (this.props.productsAddedCount !== this.state.productsAddedCount) {
      this.setState({ productsAddedCount: this.props.productsAddedCount });
      this.retrieveProducts(this.state.page);
    }
  }

  retrieveProducts(page) {
    if (page < 1) {
      page = 1;
    }
    axios.get(apiUrl + "/products?pageNumber=" + page)
      .then(res => {
        if (res.data.pageNumber > (res.data.totalPages === 0 ? 1 : res.data.totalPages)) {
          this.retrieveProducts(page - 1);
        } 
        this.setState({
          products: res.data.products,
          page: res.data.pageNumber,
          totalPages: res.data.totalPages === 0 ? 1 : res.data.totalPages
        });
      });
  }

  deleteProduct(id) {
    axios.delete(apiUrl + "/products/" + id)
      .then(res => {
        this.retrieveProducts(this.state.page);
      });
  }

  toDativeCase(word) {
    if (word.toLowerCase().endsWith("probleme")) {
      return (word + "n");
    }
    return word;
  }

  sort(arr) {
    const copy = [...arr];
    copy.sort();
    return copy;
  }

  render() {
    return (
      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Produktname</th>
              <th>Kategorien</th>
              <th>Zutaten</th>
              <th>Weitere Eigenschaften</th>
              <th>Auch geeignet bei</th>
              <th>Löschen</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.products.map(product => (
                <tr key={product.id}>
                  <td>{product.id}</td>
                  <td>{product.name}</td>
                  <td>{this.sort(product.feedCategories).map(cat => (<span key={cat}>{cat}<br /></span>))}</td>
                  <td>{this.sort(product.ingredients).map(ingr => (<span key={ingr}>{ingr}<br /></span>))}</td>
                  <td>{this.sort(product.feedProperties).map(prop => (<span key={prop}>{prop}<br /></span>))}</td>
                  <td>{this.sort(product.healthIssues).map(issue => (<span key={issue}>{this.toDativeCase(issue)}<br /></span>))}</td>
                  <td><span className="ProductTable-pointer" onClick={() => this.deleteProduct(product.id)}>✖</span></td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        <div>
          <span className="ProductTable-link" onClick={() => this.retrieveProducts(this.state.page - 1)}>&lt;&lt;</span>
          &nbsp;&nbsp;&nbsp;{this.state.page} von {this.state.totalPages}&nbsp;&nbsp;&nbsp;
          <span className="ProductTable-link" onClick={() => this.retrieveProducts(this.state.page + 1)}>&gt;&gt;</span>
        </div>
      </div>
    );
  }

}

export default ProductTable;
